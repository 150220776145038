import { Entity } from 'icerockdev-admin-toolkit';
import { ADMIN_ROLE_ADMIN, ADMIN_ROLE_EDITOR } from '../roles';
import { createItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '../api';
import { TextInputWithLength } from '../components/TextInputWithLength';
import { NewsEntity } from '../modules/NewsEntity';

export const TYPE_OFFER = 10;
export const TYPE_NEWS = 20;

export default host =>
  new NewsEntity({
    title: 'Акции/Новости',
    editable: true,
    viewable: true,
    creatable: true,
    roles: {
      all: [ADMIN_ROLE_ADMIN.toString(), ADMIN_ROLE_EDITOR.toString()],
    },
    api: {
      list: {
        url: `${host}/admin/v1/news`,
        method: 'get',
      },
      update: {
        url: `${host}/admin/v1/news`,
        method: 'put',
      },
      create: {
        url: `${host}/admin/v1/news`,
        method: 'post',
      },
      get: {
        url: `${host}/admin/v1/news`,
        method: 'get',
      },
      delete: {
        url: `${host}/admin/v1/news`,
        method: 'delete',
      },
    },
    menu: {
      enabled: true,
      label: 'Акции/Новости',
      url: '/news',
    },
    fields: [
      {
        name: 'type',
        type: 'select',
        label: 'Тип',
        required: true,
        filterable: true,
        options: {
          [TYPE_OFFER]: 'Акция',
          [TYPE_NEWS]: 'Новость',
        },
      },
      {
        name: 'title',
        label: 'Заголовок',
        required: true,
        title: true,
        filterable: true,
        type: 'custom',
        component: TextInputWithLength,
        options: {
          length: 255,
        },
      },
      {
        name: 'date',
        type: 'date',
        label: 'Дата публикации',
        sortable: true,
        hideInEdit: true,
      },
      {
        name: 'description',
        type: 'richtext',
        label: 'Описание',
        required: true,
        hideInList: true,
      },
      {
        name: 'img',
        type: 'base64image',
        label: 'Изображение',
        required: false,
        hideInList: true,
      },
      {
        name: 'visible',
        type: 'boolean',
        label: 'Видимость',
        sortable: true,
      },
    ],
    fetchItemsFn,
    getItemsFn,
    updateItemsFn,
    createItemsFn,
    deleteItemsFn,
  });
