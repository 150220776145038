import React, { useCallback } from 'react';
import { EntityFieldString } from 'icerockdev-admin-toolkit';

const TextInputWithLength = ({ options, handler, name, ...props }) => {
  const onChange = useCallback(
    val => {
      handler(options.length ? val.substr(0, options.length) : val);
    },
    [handler, options],
  );

  return <EntityFieldString {...props} handler={onChange} name={name} options={options} />;
};

export { TextInputWithLength };
