import axios from 'axios';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';

const authRestorePassword = (host, email) =>
  axios.post(`${host}/admin/v1/auth/restore-password`, { email }).catch(e => e);

const authUpdatePassword = (host, confirmationToken, password, passwordRepeat) =>
  axios
    .post(`${host}/admin/v1/auth/restore-password/change`, { confirmationToken, password, passwordRepeat })
    .catch(e => e);

const authGetTokens = (host, email, password) =>
  axios.post(`${host}/admin/v1/auth/signin`, { email, password }).catch(e => e);

const authGetProfile = (host, accessToken) =>
  axios
    .get(`${host}/admin/v1/user/profile`, {
      headers: { authorization: `Bearer ${accessToken}` },
    })
    .catch(e => e);

export const authRequestFn = host => async (email, password) => {
  try {
    const auth = await authGetTokens(host, email, password);

    if (!auth.data || !(auth.data.success || auth.data.isSuccess)) {
      throw new Error(auth.response?.data?.message);
    }

    const profile = await authGetProfile(host, auth.data.data.accessToken);

    if (!profile.data || !profile.data?.data.id) {
      throw new Error(profile.response?.data?.message);
    }

    return {
      user: {
        id: profile.data.data.id,
        email: profile.data.data.email,
        username: profile.data.data.name,
        role: String(profile.data.data.role),
      },
      tokens: {
        access: auth.data.data.accessToken,
        refresh: auth.data.data.refreshToken,
      },
      error: '',
    };
  } catch (error) {
    return {
      user: {},
      tokens: {},
      error: error.message,
    };
  }
};

export const authPasswRestoreFn = host => async email => {
  try {
    const restore = await authRestorePassword(host, email);

    if (!restore.data || !(restore.data.success || restore.data.isSuccess)) {
      throw new Error(restore.response?.data?.message);
    }

    return {
      error: '',
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const authPasswordUpdateFn = host => async (token, password, passwordRepeat) => {
  try {
    const restore = await authUpdatePassword(host, token, password, passwordRepeat);

    if (!restore.data || !(restore.data.success || restore.data.isSuccess)) {
      throw new Error(restore.response?.data?.message);
    }

    return {
      error: '',
    };
  } catch (error) {
    return {
      error: error.message,
    };
  }
};

export const tokenRefreshFn = host => async refreshToken => {
  try {
    const result = await axios.post(`${host}/admin/v1/auth/refresh/`, { refreshToken }).catch(e => e.response);

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }

    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};
