import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import Axios from 'axios';
import { observer } from 'mobx-react';
import { VEHICLE_INQUIRY_STATUS_NEW } from '../../pages/vehicle';
import { action, toJS } from 'mobx';

const AssignButton = observer(({ data, options, withToken, entity }) => {
  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      (async () => {
        await withToken(({ token }) => {
          return Axios.post(`${options.url}/${data.id}/assign`, {}, { headers: { Authorization: token } }).catch(
            e => e,
          );
        }, {}).then(
          action(() => {
            data.manager = toJS(entity.parent.auth.user.username);
            data.managerId = toJS(entity.parent.auth.user.id);
            data.status = 20;
          }),
        );
      })();
    },
    [options, data, withToken],
  );

  if (data.status !== VEHICLE_INQUIRY_STATUS_NEW) return null;

  return (
    <Button onClick={onClick} type="button" variant="outlined" color="primary">
      В работу
    </Button>
  );
});

export { AssignButton };
