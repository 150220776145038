import { createItemsFn, fetchItemsFn, getItemsFn, updateItemsFn } from '../api';

import { ADMIN_ROLE_ADMIN, ADMIN_ROLE_MANAGER } from '../roles';
import { AssignButton } from '../components/AssignButton';
import { InquryEntity } from '../components/VehicleEntity';

export const FEEDBACK_INQUIRY_TYPE_MESSAGE = 10;
export const FEEDBACK_INQUIRY_TYPE_CALL_ME = 20;

export const FEEDBACK_INQUIRY_TYPE = {
  [FEEDBACK_INQUIRY_TYPE_MESSAGE]: 'Сообщение',
  [FEEDBACK_INQUIRY_TYPE_CALL_ME]: 'Обратный звонок',
};

export const FEEDBACK_INQUIRY_STATUS_NEW = 10;
export const FEEDBACK_INQUIRY_STATUS_IN_PROGRESS = 20;
export const FEEDBACK_INQUIRY_STATUS_CLOSED = 30;

export const FEEDBACK_INQUIRY_STATUS = {
  [FEEDBACK_INQUIRY_STATUS_NEW]: 'Новая',
  [FEEDBACK_INQUIRY_STATUS_IN_PROGRESS]: 'Обрабатывается менеджером',
  [FEEDBACK_INQUIRY_STATUS_CLOSED]: 'Закрыта',
};

export default host =>
  new InquryEntity({
    title: 'Обратная связь',
    editable: true,
    viewable: true,
    roles: {
      list: [ADMIN_ROLE_ADMIN.toString(), ADMIN_ROLE_MANAGER.toString()],
      update: [ADMIN_ROLE_ADMIN.toString(), ADMIN_ROLE_MANAGER.toString()],
    },
    api: {
      list: {
        url: `${host}/admin/v1/inquiry/feedback`,
        method: 'get',
      },
      update: {
        url: `${host}/admin/v1/inquiry/feedback`,
        method: 'put',
      },
      create: {
        url: `${host}/admin/v1/inquiry/feedback`,
        method: 'post',
      },
      get: {
        url: `${host}/admin/v1/inquiry/feedback`,
        method: 'get',
      },
    },
    menu: {
      enabled: true,
      label: 'Обратная связь',
      url: '/feedback',
    },
    fields: [
      {
        name: 'type',
        label: 'Тип',
        type: 'select',
        filterable: true,
        sortable: true,
        options: FEEDBACK_INQUIRY_TYPE,
      },
      {
        name: 'name',
        label: 'Имя',
        type: 'string',
        filterable: true,
        sortable: true,
      },
      {
        name: 'status',
        type: 'select',
        label: 'Статус',
        required: true,
        sortable: true,
        options: FEEDBACK_INQUIRY_STATUS,
      },
      {
        name: 'phone',
        label: 'Телефон',
        type: 'phone',
        required: true,
      },
      {
        name: 'date',
        label: 'Дата/Время',
        type: 'date',
        sortable: true,
      },
      {
        name: 'manager',
        label: 'Менеджер',
        type: 'string',
        hideInEdit: true,
      },
      {
        name: 'createdAt',
        label: 'Дата заявки',
        type: 'date',
        hideInEdit: true,
        sortable: true,
      },
      {
        name: 'assign',
        label: ' ',
        type: 'custom',
        component: AssignButton,
        options: {
          url: `${host}/admin/v1/inquiry/feedback`,
        },
        hideInEdit: true,
      },
      {
        name: 'comment',
        label: 'Сообщение',
        type: 'richtext',
      },
    ],
    fetchItemsFn,
    getItemsFn,
    updateItemsFn,
    createItemsFn,
  });
