import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Axios from 'axios';
import { observer } from 'mobx-react';

const RegionSelectField = observer(({ data, label, value, handler, error, options, withToken, isEditing }) => {
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    if (!isEditing) return;

    (async () => {
      const result = await withToken(
        ({ token }) =>
          Axios.get(options.getRegionsUrl, {
            headers: { Authorization: token },
          }).catch(e => e),
        {},
      );

      if (!result?.data?.data) return;

      setRegions(result.data.data);
    })();
  }, [options.getRegionsUrl, withToken, isEditing]);

  const onRegionChange = useCallback(
    async event => {
      handler(parseInt(event.target.value) || null);
    },
    [handler],
  );

  return isEditing ? (
    <FormControl variant="outlined">
      <InputLabel htmlFor={label}>{label}</InputLabel>

      <Select
        variant="outlined"
        id={label}
        name={label}
        label={label}
        value={value || ''}
        onChange={onRegionChange}
        error={!!error}
        inputProps={{ className: 'select' }}
      >
        <MenuItem value="">...</MenuItem>

        {regions.length > 0 &&
          regions.map(region => (
            <MenuItem key={region.value} value={region.value}>
              {region.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ) : (
    <div>{data?.region?.name}</div>
  );
});

export { RegionSelectField };
