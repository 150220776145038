import React, { useCallback, useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { observer } from 'mobx-react';

const InquiryStatusField = observer(({ data, label, value, handler, error, options, withToken, isEditing }) => {
  const onInquiryChange = useCallback(
    async event => {
      handler(parseInt(event.target.value) || null);
    },
    [handler],
  );

  const variants = useMemo(() => {
    if (!options.types || !options.types[data.type]) {
      return {};
    }

    return options.types[data.type].reduce(
      (obj, c) => ({
        ...obj,
        [c]: options.titles[c],
      }),
      {},
    );
  }, [data.type, options.titles, options.types]);

  return isEditing ? (
    <FormControl variant="outlined">
      <InputLabel htmlFor={label}>{label}</InputLabel>

      <Select
        variant="outlined"
        id={label}
        name={label}
        label={label}
        value={value || ''}
        onChange={onInquiryChange}
        error={!!error}
        inputProps={{ className: 'select' }}
      >
        <MenuItem value="">...</MenuItem>

        {Object.keys(variants).length > 0 &&
          Object.keys(variants).map(variant => (
            <MenuItem key={variant} value={variant}>
              {options.titles[parseInt(variant)] || variant.toString()}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ) : (
    <div>{(value && options?.titles[value]) || ''}</div>
  );
});

export { InquiryStatusField };
