import React, { useState, useEffect, useCallback } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Axios from 'axios';
import { observer } from 'mobx-react';

const CitySelectField = observer(({ data, label, value, handler, error, options, withToken, isEditing }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (!data.regionId) {
      setCities([]);
      return;
    }

    if (!isEditing) return;

    (async () => {
      const result = await withToken(
        ({ token }) =>
          Axios.get(`${options.getRegionsUrl}/${data.regionId}/cities`, {
            headers: { Authorization: token },
          }).catch(e => e),
        {},
      );

      if (!result?.data?.data) return;

      setCities(result.data.data);
    })();
  }, [options.getRegionsUrl, data.regionId, withToken, isEditing]);

  const onCityChange = useCallback(
    async event => {
      handler(parseInt(event.target.value) || null);
    },
    [handler],
  );

  return isEditing ? (
    <FormControl variant="outlined">
      <InputLabel htmlFor={label}>{label}</InputLabel>

      <Select
        variant="outlined"
        id={label}
        name={label}
        label={label}
        value={value || ''}
        onChange={onCityChange}
        error={!!error}
        inputProps={{ className: 'select' }}
      >
        <MenuItem value="">...</MenuItem>

        {cities.length > 0 &&
          cities.map(city => (
            <MenuItem key={city.value} value={city.value}>
              {city.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  ) : (
    <div>{data?.city?.name || ''}</div>
  );
});

export { CitySelectField };
