export default theme => ({
  formControl: {
    '& ul': {
      height: '200px',
      overflow: 'auto',
      marginTop: '40px',
      border: '1px solid #c4c4c4',
    },
  },
});
