import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { computed, action } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import axios from 'axios';

export class AdminEntity extends Entity {
  @action
  blockItem = async () => {
    if (
      !Object.prototype.hasOwnProperty.call(this.editorData, 'id') ||
      !Object.prototype.hasOwnProperty.call(this.editorData, 'status')
    )
      return;

    const type = this.editorData.status !== 20 ? 'block' : 'unblock';

    const response = await this.parent.auth.withToken(({ token }) =>
      axios
        .put(`${this.api.update.url}/${this.editorData.id}/${type}`, {}, { headers: { authorization: token } })
        .catch(e => e.response),
    );

    if (response.status !== 200) return;

    this.editorData.status = this.editorData.status === 20 ? 10 : 20;
  };

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }) => (
      <Button variant="contained" color="primary" type="button" style={{ marginRight: 10 }} onClick={this.blockItem}>
        {this.editorData?.status !== 20 ? 'Блокировать' : 'Разблокировать'}
      </Button>
    ));
  }
}
