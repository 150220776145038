import React from 'react';
import { Entity } from 'icerockdev-admin-toolkit';
import { computed, action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { EntityList } from './components/EntityList/EntityList';

export class NewsEntity extends Entity {
  deletionText = 'Вы уверены, что хотите удалить новость?';
  sortDir = 'ASC';
  @observable deleteItemsFn = undefined;
  @action
  deleteNews = async (id, isRedirecting) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.deletionText)) return;

    this.isLoading = true;
    try {
      const response = await this.parent.auth.withToken(this.deleteItemsFn, {
        url: `${this?.api?.delete.url}/${id}`,
      });

      if (isRedirecting && response.status === 200) {
        if (this.parent?.history?.length && this.parent?.history.goBack) {
          // eslint-disable-next-line no-unused-expressions
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          // eslint-disable-next-line no-unused-expressions
          this.parent?.history.push(this.menu.url);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-expressions
      this.parent?.notifications.showError(e.message.toString());
    } finally {
      this.isLoading = false;
      this.fetchItems();
    }
  };

  @computed
  get ListBody() {
    return observer(() => (
      <EntityList
        fields={this.fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        canDelete={true}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
        deleteFunction={this.deleteNews}
      ></EntityList>
    ));
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }) => (
      <Button
        variant="outlined"
        color="secondary"
        type="button"
        style={{ marginRight: 10 }}
        onClick={() => this.deleteNews(id, true)}
      >
        Удалить
      </Button>
    ));
  }
}
