import { ENTITY_ERRORS, UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import axios from 'axios';

export const fetchItemsFn = async ({ url, token, page, count: limit, sortBy, sortDir, filter }) => {
  try {
    const filters = filter.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {});
    const offset = (page && limit && page * limit) || 0;
    const orderBy = sortDir.toUpperCase();
    const result = await axios
      .get(url, {
        headers: { Authorization: token },
        params: {
          offset,
          limit,
          sortBy,
          orderBy,
          ...filters,
        },
      })
      .catch(e => e.response);

    if (result && result.status === 401) {
      throw new Error(UNAUTHORIZED);
    }

    if (!result.data || !(result.data.success || result.data.isSuccess)) {
      throw new Error(result.response?.data?.message || ENTITY_ERRORS.CANT_LOAD_ITEMS);
    }

    const totalCount = parseInt(result.headers['x-total-count'] || 0);

    return Promise.resolve({
      data: { list: result.data.data, totalCount },
      error: '',
    });
  } catch (error) {
    return {
      data: { list: [], totalCount: 0 },
      error: error.message,
    };
  }
};

export const getItemsFn = async ({ url, token, id }) => {
  try {
    const result = await axios.get(`${url}/${id}`, { headers: { Authorization: token } }).catch(e => e);

    if (!result.data || !(result.data.success || result.data.isSuccess)) {
      throw new Error(result.response?.data?.message || ENTITY_ERRORS.CANT_LOAD_ITEMS);
    }

    return Promise.resolve({
      data: result.data.data,
      error: '',
    });
  } catch (error) {
    return {
      data: {},
      error: error.message,
    };
  }
};

export const updateItemsFn = async ({ url, token, data }) => {
  try {
    const result = await axios.put(`${url}/${data.id}`, data, { headers: { Authorization: token } }).catch(e => e);

    if (!result.data || !(result.data.success || result.data.isSuccess)) {
      throw new Error(parseResponseErrorMessage(result.response) || ENTITY_ERRORS.CANT_UPDATE_ITEM);
    }

    return Promise.resolve({
      data: result.data.data,
      error: '',
    });
  } catch (error) {
    return {
      data: {},
      error: error.message,
    };
  }
};

export const createItemsFn = async ({ url, token, data }) => {
  try {
    const result = await axios.post(`${url}`, data, { headers: { Authorization: token } }).catch(e => e);

    if (!result.data || !(result.data.success || result.data.isSuccess)) {
      throw new Error(parseResponseErrorMessage(result.response) || ENTITY_ERRORS.CANT_UPDATE_ITEM);
    }

    return Promise.resolve({
      data: result.data.data,
      error: '',
    });
  } catch (error) {
    return {
      data: {},
      error: error.message,
    };
  }
};

export const parseResponseErrorMessage = response => {
  let dataList = response?.data?.data;

  if (Array.isArray(dataList) && dataList.length > 0) {
    return dataList[0].message;
  }

  return response?.data?.message || response?.message;
};

export const deleteItemsFn = async ({ url, token }) => {
  return await axios.delete(`${url}`, { headers: { Authorization: token } });
};
