import { Entity, EntityList, EntityViewer } from 'icerockdev-admin-toolkit';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ADMIN_ROLE_MANAGER } from '../../roles';

export class InquryEntity extends Entity {
  @computed
  get ViewerBody() {
    // Show assign button only for managers
    const isManager = this.parent.auth.user.role === ADMIN_ROLE_MANAGER.toString();
    const fields = this.fields.filter(field => field.name !== 'assign' || isManager);

    return observer(({ id }) => (
      <EntityViewer
        id={id}
        fields={fields}
        url={this.menu.url}
        errors={this.editorFieldErrors}
        onSave={() => {}}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing={false}
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
      />
    ));
  }

  @computed
  get ListBody() {
    // Show assign button only for managers
    const isManager = this.parent.auth.user.role === ADMIN_ROLE_MANAGER.toString();
    const fields = this.fields.filter(field => field.name !== 'assign' || isManager);

    return observer(() => (
      <EntityList
        fields={fields}
        data={this.data}
        extra={this.ListExtra}
        isLoading={this.isLoading}
        url={this.menu.url}
        selected={this.selected}
        sortBy={this.sortBy}
        sortDir={this.sortDir}
        canView={this.viewable}
        canEdit={this.editable && this.canEdit}
        canSelect={this.selectable}
        setSelected={this.setSelected}
        onSortChange={this.setSort}
        withToken={this.parent?.auth?.withToken}
        entity={this}
      />
    ));
  }
}
